<template>

  <div class="page container">

    <div class="columns left">
      <div class="column">
        <h1>Datenschutz</h1>
        <p>
          Wir von OmniMundi nehmen die Belange des Datenschutzes sehr ernst und möchten sicherstellen,
          dass Ihre Privatsphäre bei der Nutzung unseres Angebots „Der Entfalter“
          jederzeit
          geschützt wird. In der folgenden Datenschutzerklärung möchten wir Ihnen daher erläutern, wie mit
          Ihren Daten unter entfalter.de umgegangen wird.</p>

        <div class="card" id="gaoptout">
          <h4>Google Analytics</h4>
          <p>Wenn Sie nicht von Google Analytics getrackt werden wollen, k&ouml;nnen Sie das hier
            abstellen:</p>
          <p>
            <input id="optout" type="checkbox" v-model="gaActive"/>
            <label for="optout" v-if="gaActive">Google Tracking ist aktiv</label>
            <label for="optout" v-if="!gaActive">Google Tracking ist inaktiv</label>
          </p>
          <p><a href="#ga">Hier finden Sie Details zur Datenerhebung via Google Analytics.</a></p>
        </div>

        <p><b>Inhalt</b></p>
        <ol>
          <li>
            <p>
	<span style="display: inline-block; border: none; padding: 0in">Allgemeine
	Informationen über die Erhebung personenbezogener Daten </span>
            </p>
          </li>
          <li>
            <p>
	<span style="display: inline-block; border: none; padding: 0in">Kontaktinformationen
	des Verantwortlichen und Datenschutzbeauftragten </span>
            </p>
          </li>
          <li>
            <p>
	<span style="display: inline-block; border: none; padding: 0in">Erläuterungen
	zu den Rechtsgrundlagen und der Speicherdauer </span>
            </p>
          </li>
          <li>
            <p>
	<span style="display: inline-block; border: none; padding: 0in">Ihre
	Rechte </span>
            </p>
          </li>
          <li>
            <p>
	<span style="display: inline-block; border: none; padding: 0in">Personenbezogene
	Daten und deren Verwendung </span>
            </p>
          </li>
          <li>
            <p>
	<span style="display: inline-block; border: none; padding: 0in">Verwendung
	von Cookies </span>
            </p>
          </li>
          <li>
            <p>
	<span style="display: inline-block; border: none; padding: 0in">Übermittlung
	an Dritte </span>
            </p>
          </li>
          <li>
            <p>
	<span style="display: inline-block; border: none; padding: 0in">Sicherheitsstandards
	</span>
            </p>
          </li>
          <li>
            <p>
	<span style="display: inline-block; border: none; padding: 0in">Änderungen
	an dieser Erklärung </span>
            </p>
          </li>
        </ol>
        <br/>
        <br/>
        <p><b>1.
          Allgemeine Informationen über die Erhebung personenbezogener Daten </b>
        </p>
        <p>Die <span>Website
www.entfalter.de</span> wird betrieben von der OmniMundi GmbH &amp;
          Co. KG („OmniMundi“). OmniMundi ist der Schutz Ihrer
          personenbezogenen Daten besonders wichtig. Diese Datenschutzerklärung
          beschreibt, wie OmniMundi („wir“, „uns“ und „unsere“)
          die über entfalter.de („Entfalter -Website“) erfassten
          personenbezogenen Daten nutzt und schützt. <br/>
          Personenbezogene
          Daten sind alle Daten, die auf Sie persönlich beziehbar sind, wie
          beispielsweise Ihre Anrede, Ihr Name, Ihre Anschrift, Ihre IP-Adresse
          etc. Ihre personenbezogenen Daten werden von uns nur gemäß den
          Bestimmungen der EU Datenschutzgrundverordnung („<b>DSGVO</b>“)
          sowie anderer Bestimmungen des europäischen sowie des jeweils
          anwendbaren nationalen Datenschutzrechts erhoben und verarbeitet.
          <br/>
          Die Erhebung und Verwendung personenbezogener Daten erfolgt
          nur, nachdem Sie hierzu eingewilligt haben oder die Verarbeitung der
          Daten durch gesetzliche Vorschriften gestattet ist. Die nachfolgenden
          Bestimmungen informieren Sie jeweils über Art, Umfang und Zweck der
          Erhebung und Verarbeitung Ihrer personenbezogenen Daten.<br/>
          Für
          den Fall, dass wir für einzelne Funktionen unseres Angebots bzw.
          unserer Dienstleistungen auf beauftragte Dienstleister zurückgreifen
          oder Ihre Daten für Informationen über unsere Produkte oder
          Analyse-Zwecke nutzen, informieren wir Sie zudem untenstehend im
          Detail über die jeweiligen Vorgänge. An dieser Stelle informieren
          wir Sie auch über die festgelegten Kriterien und die Speicherdauer.
          Auch informieren wir Sie über Ihre Rechte bezüglich jeder
          Datenverarbeitung.<br/>
          Diese Datenschutzerklärung bezieht sich
          ausschließlich auf unsere Entfalter -Website. Für den Fall, dass
          Sie über Links von unserer Entfalter -Website auf Websites Dritter
          weitergeleitet werden, informieren Sie sich bitte dort über den
          jeweiligen Umgang mit Ihren Daten.</p>
        <p><b>2.
          Kontaktinformationen des Verantwortlichen und Datenschutzbeauftragten
        </b>
        </p>
        <p>Der für das
          Datenschutzrecht Verantwortliche im Sinne der DSGVO sowie aller
          anderen anwendbaren datenschutzrechtlichen Bestimmungen der EU
          („Verantwortlicher“) ist OmniMundi. Sollten Sie Fragen,
          Anregungen oder Kritik in Bezug auf den Datenschutz unserer Website
          haben, so kontaktieren Sie bitte:</p>
        <p><br/>

        </p>
        <p style="margin-bottom: 0in;; page-break-before: always">
          <b>OmniMundi GmbH &amp; Co. KG<br/>
          </b>Schönebecker Straße 51<br/>
          39104 Magdeburg<br/>
          Kontakt:kontakt@odyssees-end.de
        </p>
        <br/><br/>
        <p><b>3.
          Erläuterungen zu den Rechtsgrundlagen und der Speicherdauer </b>
        </p>
        <p><b>3.1.
          Rechtsgrundlagen für die Verarbeitung personenbezogener Daten </b>
        </p>
        <p>Soweit wir für die
          Verarbeitungsvorgänge personenbezogener Daten eine Einwilligung von
          Ihnen einholen, ist <i>Artikel 6 Absatz 1 Satz 1 Buchstabe a DSGVO</i>
          die Rechtsgrundlage für die Verarbeitung Ihrer personenbezogener
          Daten. Jede Einwilligung kann von Ihnen mit Wirkung für die Zukunft
          widerrufen werden. <br/>
          Bei der Verarbeitung von personenbezogenen
          Daten, die zur Erfüllung eines Vertrages mit Ihnen bzw. Ihrem
          Unternehmen erforderlich sind, ist <i>Artikel 6 Absatz 1 Satz 1
            Buchstabe b DSGVO</i> die entsprechende Rechtsgrundlage. Dies gilt
          auch für Verarbeitungsvorgänge, die bereits vorvertraglich relevant
          werden.<br/>
          Soweit eine Verarbeitung Ihrer personenbezogenen Daten
          zur Erfüllung einer unserer rechtlichen Verpflichtungen erforderlich
          ist, dient <i>Artikel 6 Absatz 1 Satz 1 Buchstabe c DSGVO</i> als
          Rechtsgrundlage.<br/>
          Ist die Verarbeitung zur Wahrung eines
          berechtigten Interesses unseres Unternehmens oder eines Dritten
          erforderlich und überwiegen Ihre Interessen, Grundrechte und
          Grundfreiheiten unser berechtigtes Interesse nicht, so dient <i>Artikel
            6 Absatz 1 Satz 1 Buchstabe f DSGVO</i> als Rechtsgrundlage für die
          Verarbeitung.</p>
        <p><b>3.2.
          Speicherdauer und Löschung der Daten</b></p>
        <p>Die von uns
          erhobenen, verarbeiteten und gespeicherten personenbezogenen Daten
          werden von uns grundsätzlich nur so lange gespeichert, wie es der
          konkrete Zweck der Speicherung verlangt. Fällt der Zweck der
          Speicherung weg, werden Ihre Daten gelöscht bzw. deren Verarbeitung
          eingeschränkt.<br/>
          Darüber hinaus kann es aber sein, dass
          europäische Verordnungen, anwendbare nationale Gesetze oder sonstige
          Vorschriften eine längere Speicherung der von uns verarbeiteten
          Daten verlangen. Laufen diese Speicherfristen ab, löschen wir Ihre
          Daten oder schränken die Verarbeitung dieser ein.</p>
        <p><b>4.
          Ihre Rechte </b>
        </p>
        <p>Soweit wir
          personenbezogene Daten von Ihnen verarbeiten, sind Sie „<b>betroffene Person</b>“ im Sinne der DSGVO. Als betroffene Person stehen Ihnen
          die folgenden Rechte gegenüber OmniMundi zu:</p>
        <p><b>4.1.
          Recht auf Auskunft bezüglich der Verarbeitung</b></p>
        <p>Sie können jederzeit im Rahmen der gesetzlichen Bestimmungen von uns Auskunft
          darüber verlangen, ob personenbezogene Daten von uns verarbeitet
          werden. Ist dies der Fall, haben Sie das Recht, über den Umfang der
          Datenverarbeitung Auskunft zu verlangen (vgl. <i>Artikel 15 DSGVO</i>).</p>
        <p><b>4.2.
          Recht auf Berichtigung</b></p>
        <p>Sie haben ein Recht
          auf Berichtigung und/oder Vervollständigung Ihrer Daten gegenüber
          OmniMundi, sofern die Sie betreffenden verarbeiteten
          personenbezogenen Daten unrichtig oder unvollständig sind (vgl.
          <i>Artikel 16 DSGVO</i>).</p>
        <p><b>4.3.
          Recht auf Einschränkung der Verarbeitung </b>
        </p>
        <p>Liegen die
          Voraussetzungen hierfür vor, können Sie die Einschränkung der
          Verarbeitung Ihrer personenbezogenen Daten verlangen (vgl. <i>Artikel
            18 DSGVO</i>).</p>
        <p><b>4.4.
          Recht auf Löschung</b></p>
        <p>Sie können von
          OmniMundi verlangen, dass die Sie betreffenden
          personenbezogenen Daten unverzüglich gelöscht werden, sofern die
          Voraussetzungen hierfür vorliegen. Das Recht auf Löschung besteht
          nicht, soweit die Verarbeitung erforderlich ist (vgl. <i>Artikel 17
            DSGVO</i>).</p>
        <p><b>4.5.
          Recht auf Unterrichtung</b></p>
        <p>Haben Sie das Recht
          auf Berichtigung, Löschung oder Einschränkung der Verarbeitung
          gegenüber OmniMundi geltend gemacht, ist OmniMundi
          verpflichtet, allen Empfängern, denen die Sie betreffenden
          personenbezogenen Daten offengelegt wurden, diese Berichtigung oder
          Löschung der Daten oder Einschränkung der Verarbeitung mitzuteilen,
          es sei denn, dies erweist sich als unmöglich oder ist mit einem
          unverhältnismäßigen Aufwand verbunden (vgl. <i>Artikel 19 DSGVO</i>).</p>
        <p><b>4.6.
          Recht auf Datenübertragbarkeit</b></p>
        <p>Sie haben das Recht,
          die Sie betreffenden personenbezogenen Daten, die Sie OmniMundi
          bereitgestellt haben zu erhalten. Außerdem haben Sie das Recht,
          diese Daten einem anderen Unternehmen ohne Behinderung durch
          OmniMundi, dem die personenbezogenen Daten bereitgestellt
          wurden, zu übermitteln, sofern die Voraussetzungen hierfür
          vorliegen (vgl. <i>Artikel 20 DSGVO</i>).</p>
        <p><b>4.7.
          Widerspruchsrecht</b></p>
        <p><b>Sie
          haben das Recht, aus Gründen, die sich aus ihrer besonderen
          Situation ergeben, jederzeit gegen die Verarbeitung der Sie
          betreffenden personenbezogenen Daten, die aufgrund von <i>Artikel 6
            Absatz 1 Satz 1 Buchstabe f DSGVO</i> erfolgt, Widerspruch einzulegen
          (vgl. <i>Artikel 21 Absatz 1 DSGVO</i>). Folge des Widerspruchs ist
          es, dass OmniMundi die Sie betreffenden personenbezogenen Daten
          nicht mehr verarbeitet, es sei denn, OmniMundi kann zwingende
          schutzwürdige Gründe für die Verarbeitung nachweisen, die Ihre
          Interessen, Rechte und Freiheiten überwiegen, oder die Verarbeitung
          dient der Geltendmachung, Ausübung oder Verteidigung von
          Rechtsansprüchen.</b></p>
        <p><b>Werden
          die Sie betreffenden personenbezogenen Daten verarbeitet, um über
          unsere Produkte zu informieren, haben Sie das Recht, jederzeit
          Widerspruch gegen die Verarbeitung der Sie betreffenden
          personenbezogenen Daten zum Zwecke derartiger Informationen
          einzulegen (<i>Artikel 21 Absatz 2 DSGVO</i>).</b></p>
        <p><b>Über
          Ihren Widerspruch können Sie uns unter folgenden Kontaktdaten
          informieren:</b></p>
        <p><b>OmniMundi GmbH &amp; Co. KG<br/>
          Schönebecker Straße 51<br/>
          39104 Magdeburg<br/>
          Kontakt: <span>kontakt@odyssees-end.de</span>
        </b>
        </p>
        <p><b>4.8.
          Recht auf Widerruf der datenschutzrechtlichen Einwilligungserklärung</b></p>
        <p>Sofern Sie eine
          datenschutzrechtliche Einwilligungserklärung abgegeben haben, können
          Sie diese jederzeit widerrufen (vgl. <i>Artikel 7 DSGVO</i>). Durch
          den Widerruf der Einwilligung wird die Rechtmäßigkeit der aufgrund
          der Einwilligung bis zum Widerruf erfolgten Verarbeitung nicht
          berührt.</p>
        <p><b>4.9.
          Recht auf Beschwerde bei einer Aufsichtsbehörde</b></p>
        <p>Unbeschadet eines
          anderweitigen verwaltungsrechtlichen oder gerichtlichen Rechtsbehelfs
          steht Ihnen das Recht auf Beschwerde bei einer Aufsichtsbehörde,
          insbesondere in dem Mitgliedstaat ihres Aufenthaltsorts, ihres
          Arbeitsplatzes oder des Ortes des mutmaßlichen Verstoßes, zu, wenn
          Sie der Ansicht sind, dass die Verarbeitung der Sie betreffenden
          personenbezogenen Daten gegen die DSGVO verstößt (vgl. <i>Artikel
            77 DSGVO</i>).</p>
        <p><b>5.
          Personenbezogene Daten und deren Verwendung</b></p>
        <p>Soweit Sie das
          Onlineprodukt „Der Entfalter“ von uns nutzen, ist die Erhebung,
          Verarbeitung und Nutzung (personenbezogener) Daten notwendig. Diese
          bei der Nutzung erhobenen, verarbeiteten und genutzten Daten werden
          ausschließlich zur Ausführung der Funktionalitäten der Produkte
          und deren Abrechnungsmodalitäten genutzt. Ihre zur Nutzung eines
          Online-Produkts eingegebenen (personenbezogenen) Daten werden in
          Rechenzentren unserer Dienstleister gespeichert und verarbeitet.
          Diese verwenden die Daten nur für die Bereitstellung der Dienste.
          Hierzu gehören insbesondere Wartungs- und Reparaturarbeiten. Bei der
          Durchführung dieser Arbeiten lässt sich im Einzelfall nicht
          ausschließen, dass die betreffenden Mitarbeiter des Dienstleisters
          von Ihren (personenbezogenen) Daten Kenntnis erlangen.</p>
        <p><b>5.1
          Erhebung, Verarbeitung und Nutzung personenbezogener Daten bei der
          Nutzung unserer Online-Produkte</b></p>
        <p>In folgenden Fällen
          verwenden wir personenbezogene Daten:</p>
        <table cellpadding="7" cellspacing="0">
          <col>
          <col>
          <tr valign="top">
            <td style="border: none; padding: 0in">
              <p style="border: none; padding: 0in"><b>-&nbsp;</b></p>
            </td>
            <td style="border: none; padding: 0in">
              <p style="border: none; padding: 0in; font-variant: normal; letter-spacing: normal; font-style: normal">
			<span style="display: inline-block; border: none; padding: 0in">Bestellung / Bezahlung
			von Produkten</span></p>
            </td>
          </tr>
          <tr valign="top">
            <td style="border: none; padding: 0in">
              <p style="border: none; padding: 0in"><b>-&nbsp;</b></p>
            </td>
            <td style="border: none; padding: 0in">
              <p style="border: none; padding: 0in; font-variant: normal; letter-spacing: normal; font-style: normal">
			<span style="display: inline-block; border: none; padding: 0in">Nutzung
			unserer digitalen Produkte und Onlineangebote</span></p>
            </td>
          </tr>
          <tr valign="top">
            <td style="border: none; padding: 0in">
              <p style="border: none; padding: 0in"><b>-&nbsp;</b></p>
            </td>
            <td style="border: none; padding: 0in">
              <p style="border: none; padding: 0in; font-variant: normal; letter-spacing: normal; font-style: normal">
			<span style="display: inline-block; border: none; padding: 0in">Abonnieren/Abbestellen
			von Newslettern</span></p>
            </td>
          </tr>
          <tr valign="top">
            <td style="border: none; padding: 0in">
              <p style="border: none; padding: 0in"><b>-&nbsp;</b></p>
            </td>
            <td style="border: none; padding: 0in">
              <p style="border: none; padding: 0in; font-variant: normal; letter-spacing: normal; font-style: normal">
			<span style="display: inline-block; border: none; padding: 0in">Kontaktaufnahme
			und Kundensupport</span></p>
            </td>
          </tr>
          <tr valign="top">
            <td style="border: none; padding: 0in">
              <p style="border: none; padding: 0in"><b>-&nbsp;</b></p>
            </td>
            <td style="border: none; padding: 0in">
              <p style="border: none; padding: 0in; font-variant: normal; letter-spacing: normal; font-style: normal">
			<span style="display: inline-block; border: none; padding: 0in">um
			Komfort- und Sicherheitsfunktionen auf unserer Seite anbieten zu
			können </span>
              </p>
            </td>
          </tr>
        </table>

        <p>Die Verarbeitung und
          Nutzung von personenbezogenen Daten ist insbesondere auch für die
          Bestellabwicklung oder die Nutzung des Online-Produkts „Der
          Entfalter“ notwendig, um beispielsweise individuelle
          Benutzereinstellungen festlegen und ändern zu können. Der Zugang zu
          diesen Informationen ist durch ein persönliches Kunden-Login und ein
          Passwort geschützt.</p>
        <p>Nur soweit Sie
          zusätzlich durch das Setzen eines Hakens in eine Checkbox hierin
          gesondert eingewilligt haben, versenden wir Newsletter an Sie, um Sie
          über Neuigkeiten rund um den Entfalter zu informieren. Die
          Einwilligung in die Versendung der Newsletter können Sie jederzeit
          durch die Widerrufsmöglichkeit, die am Ende eines jeden Newsletters
          durch einen Link aufgezeigt wird, widerrufen. Alternativ kann eine
          Abbestellung über eine E-mail an info@omnimundi.de erfolgen.</p>
        <p>Alle mit der
          E-Mail-Adresse verbundenen persönlichen Daten werden 7 Tage nach
          Abmeldung aus der Mailingsoftware gelöscht. Lediglich die
          anonymisierte E-Mail Adresse wird in einer E-Mail-Sperrliste
          vorgehalten.</p>
        <p>Sie können der
          Erhebung, Verarbeitung bzw. Nutzung Ihrer personenbezogenen Daten zum
          Zwecke der Newsletter Versendung auch, wie unter Punkt 2. beschrieben
          jederzeit widersprechen.&nbsp;
        </p>
        <p><b>6. Verwendung von Cookies </b>
        </p>
        <p>Zusätzlich zu den
          zuvor genannten Daten werden bei der Nutzung unserer Entfalter
          -Website verschiedene Arten von Cookies eingesetzt und auf Ihrem
          Computer gespeichert. Cookies sind kleine Textdateien, die auf Ihrem
          Computer oder Ihren mobilen Endgeräten gespeichert werden, wenn Sie
          unsere Entfalter -Website besuchen. Uns fließen durch das Setzen der
          Cookies verschiedene Informationen zu.<br/>
          Wir verwenden die
          folgenden Arten von Cookies:</p>
        <ul>
          <li/>
          <p>
	<span style="display: inline-block; border: none; padding: 0in">Cookies
	können erforderlich sein, damit unsere Website ordnungsgemäß
	funktioniert. Durch das Setzen von Cookies können wir
	beispielsweise feststellen, dass Sie unsere Website besucht haben.</span></p>
          <li/>
          <p>
	<span style="display: inline-block; border: none; padding: 0in">Durch
	den Einsatz von Cookies können wir Ihnen zudem nutzerfreundlichere
	Services bereitstellen, die ohne das Setzen von Cookies schlichtweg
	nicht möglich wären.</span></p>
          <li/>
          <p>
	<span style="display: inline-block; border: none; padding: 0in">Cookies
	ermöglichen uns, wie bereits erwähnt, Sie auf unserer Website
	wiederzuerkennen. Zweck dieser Wiedererkennung ist es, Ihnen die
	Verwendung unserer Internetseite zu erleichtern.</span></p>
          <li/>
          <p>
	<span style="display: inline-block; border: none; padding: 0in">Cookies
	können aber auch zur Analyse der Website-Nutzung verwendet werden.
	Hierbei wird dann z.B. festgestellt, wie viele Nutzer die Website
	besuchen und an welchen Stellen die Website unter Umständen
	verbessert werden kann. Bei dieser Analyse wird allerdings keine
	Verbindung zwischen Ihnen und den aufgrund der erhobenen Daten
	erstellten Statistiken hergestellt. Soweit wir Cookies zur
	Website-Analyse einsetzen, werden wir darüber informieren. </span>
          </p>
        </ul>
        <p>Wir nutzen die durch
          Cookies gesammelten Daten so lange, wie sie für den jeweiligen
          genannten Zweck erforderlich sind.
        </p>
        <p>Cookies werden auf
          Ihrem Computer gespeichert. Sie können jederzeit entscheiden, ob Sie
          die Cookies von Ihrem Computer löschen. Durch die Einstellungen in
          Ihrem Browser können Sie selbst festlegen, ob die Übertragung der
          Cookies von Ihrem Computer an uns deaktiviert, beschränkt oder die
          Cookies gar ganz gelöscht werden sollen. Deaktivieren Sie alle
          Cookies für unsere Website, können möglicherweise nicht mehr alle
          Funktionen der Website vollumfänglich genutzt werden.</p>
        <p>Die folgende Liste
          bietet weitere Informationen zum Deaktivieren oder Verwalten Ihrer
          Cookie-Einstellungen im von Ihnen verwendeten Browser:</p>
        <ul>
          <li/>
          <p>
	<span style="display: inline-block; border: none; padding: 0in">Safari:
	</span><u><a href="http://help.apple.com/safari/mac/8.0/">http://help.apple.com/safari/mac/8.0/#/sfri11471</a></u>
          </p>
          <li/>
          <p>
	<span style="display: inline-block; border: none; padding: 0in">iPhone,
	iPad or iPod: </span><u><a href="https://support.apple.com/en-gb/HT201265">https://support.apple.com/en-gb/HT201265</a></u>
          </p>
          <li/>
          <p>
	<span style="display: inline-block; border: none; padding: 0in">Google
	Chrome: </span><u><a
              href="https://support.google.com/chrome/answer/95647?hl=en">https://support.google.com/chrome/answer/95647?hl=en</a></u>
          </p>
          <li/>
          <p>
	<span style="display: inline-block; border: none; padding: 0in">Internet
	Explorer / Edge:
	</span><u><a
              href="http://windows.microsoft.com/en-GB/windows-vista/Block-or-allow-cookies">http://windows.microsoft.com/en-GB/windows-vista/Block-or-allow-cookies</a></u>
          </p>
          <li/>
          <p>
	<span style="display: inline-block; border: none; padding: 0in">Firefox:
	</span><u><a href="https://support.mozilla.org/en-US/kb/enable-and-disable-cookies-website-preferences">https://support.mozilla.org/en-US/kb/enable-and-disable-cookies-website-preferences</a></u>
          </p>
        </ul>

        <br/>
        <p id="ga"><b>6.1. Google Analytics</b></p>
        <p>Soweit Sie Ihre Einwilligung erklärt haben, wird auf dieser Website Google Analytics eingesetzt,
          ein Webanalysedienst der Google Ireland Limited (&bdquo;Google&ldquo;). Die Nutzung umfasst die
          Betriebsart &bdquo;Universal Analytics&ldquo;. Hierdurch ist es möglich, Daten, Sitzungen und
          Interaktionen über mehrere Geräte hinweg einer pseudonymen User-ID zuzuordnen und so die
          Aktivitäten eines Nutzers geräteübergreifend zu analysieren. Dieser Datenschutzhinweis wird zur
          Verfügung gestellt von <a href="https://www.intersoft-consulting.de" target="_blank">www.intersoft-consulting.de</a>.
        </p>

        <p>Google Analytics verwendet sog. &bdquo;Cookies&ldquo;, Textdateien, die auf Ihrem Computer
          gespeichert werden und die eine Analyse der Benutzung der Website durch Sie ermöglichen. Die
          durch das Cookie erzeugten Informationen über Ihre Benutzung dieser Website werden in der Regel
          an einen Server von Google in den USA übertragen und dort gespeichert. Im Falle der Aktivierung
          der IP-Anonymisierung auf dieser Website, wird Ihre IP-Adresse von Google jedoch innerhalb von
          Mitgliedstaaten der Europäischen Union oder in anderen Vertragsstaaten des Abkommens über den
          Europäischen Wirtschaftsraum zuvor gekürzt. Wir weisen Sie darauf hin, dass auf dieser Webseite
          Google Analytics um eine IP-Anonymisierung erweitert wurde, um eine anonymisierte Erfassung von
          IP-Adressen (sog. IP-Masking) zu gewährleisten. Die im Rahmen von Google Analytics von Ihrem
          Browser übermittelte IP-Adresse wird nicht mit anderen Daten von Google zusammengeführt. Nähere
          Informationen zu Nutzungsbedingungen und Datenschutz finden Sie unter <a
              href="https://www.google.com/analytics/terms/de.html" target="_blank">https://www.google.com/analytics/terms/de.html</a>
          bzw. unter <a href="https://policies.google.com/?hl=de" target="_blank">https://policies.google.com/?hl=de</a>.
        </p>

        <p><strong>Zwecke der Verarbeitung</strong><br>
          Im Auftrag des Betreibers dieser Website wird Google diese Informationen benutzen, um Ihre
          Nutzung der Website auszuwerten, um Reports über die Websiteaktivitäten zusammenzustellen und um
          weitere mit der Websitenutzung und der Internetnutzung verbundene Dienstleistungen gegenüber dem
          Websitebetreiber zu erbringen.</p>
        <p><strong>Rechtsgrundlage</strong><br>
          Die Rechtsgrundlage für den Einsatz von Google Analytics ist Ihre Einwilligung gemäß <a
              href="https://dsgvo-gesetz.de/art-6-dsgvo/" target="_blank">Art. 6 Abs. 1 S.1 lit. a
            DSGVO</a>.</p>
        <p><strong>Empfänger / Kategorien von Empfängern</strong><br>
          Der Empfänger der erhobenen Daten ist Google.</p>
        <p><strong>Übermittlung in Drittstaaten</strong><br>
          Die personenbezogenen Daten werden unter dem EU-US Privacy Shield auf Grundlage des
          Angemessenheitsbeschlusses der Europäischen Kommission in die USA übermittelt. Das Zertifikat
          können Sie <a href="https://www.privacyshield.gov/participant?id=a2zt000000001L5AAI"
                        target="_blank">hier</a> abrufen.</p>
        <p><strong>Dauer der Datenspeicherung</strong><br>
          Die von uns gesendeten und mit Cookies, Nutzerkennungen (z. B. User-ID) oder Werbe-IDs
          verknüpften Daten werden nach 14 Monaten automatisch gelöscht. Die Löschung von Daten, deren
          Aufbewahrungsdauer erreicht ist, erfolgt automatisch einmal im Monat.</p>
        <p><strong>Betroffenenrechte</strong><br>
          Sie können Ihre Einwilligung jederzeit mit Wirkung für die Zukunft widerrufen, indem Sie die
          Speicherung der Cookies durch eine entsprechende Einstellung Ihrer Browser-Software verhindern;
          wir weisen Sie jedoch darauf hin, dass Sie in diesem Fall gegebenenfalls nicht sämtliche
          Funktionen dieser Website vollumfänglich werden nutzen können.</p>

        <p>Sie können darüber hinaus die Erfassung der durch das Cookie erzeugten und auf Ihre Nutzung der
          Website bezogenen Daten (inkl. Ihrer IP-Adresse) an Google sowie die Verarbeitung dieser Daten
          durch Google verhindern, indem Sie das <a href="https://tools.google.com/dlpage/gaoptout?hl=de"
                                                    target="_blank">Browser-Add-on</a> herunterladen und
          installieren. Opt-Out-Cookies verhindern die zukünftige Erfassung Ihrer Daten beim Besuch dieser
          Website. Um die Erfassung durch Universal Analytics über verschiedene Geräte hinweg zu
          verhindern, müssen Sie das Opt-Out auf allen genutzten Systemen durchführen. Wenn Sie hier
          klicken, wird das Opt-Out-Cookie gesetzt: <a href="#gaoptout"><strong>Google Analytics
            deaktivieren</strong></a></p>

        <br/>

        <p><b>7.
          Übermittlung an Dritte </b>
        </p>
        <p><b>7.1.
          Grundsätze</b></p>
        <p>Wir werden Ihre
          persönlichen Daten generell nur im Rahmen der geltenden
          Datenschutzgesetze an Dienstleister weitergeben und Sie hierüber
          ausreichend informieren.Wir können personenbezogene Daten gegenüber
          den von uns beauftragten Dienstleistern offenlegen und diese
          verpflichten, Dienstleistungen in unserem Namen durchzuführen
          (Auftragsverarbeitung). Solche Dienstleister können externe
          Dienstleister von OmniMundi sein. Dabei beachten wir die
          strengen anzuwendenden nationalen und europäischen
          Datenschutzbestimmungen. Die Dienstleister sind unseren Weisungen
          unterworfen und unterliegen strengen vertraglichen Beschränkungen in
          Bezug auf die Verarbeitung von personenbezogenen Daten. Hiernach ist
          eine Verarbeitung nur erlaubt, soweit es für die Durchführung der
          Dienstleistungen in unserem Namen oder zur Einhaltung rechtlicher
          Anforderungen erforderlich ist. Es wird im Vorhinein genau von uns
          festgelegt, welche Rechte und Pflichten unsere Dienstleister in Bezug
          auf personenbezogene Daten haben sollen.<br/>
          Wir können
          personenbezogene Daten gegenüber einem Dritten offenlegen, wenn wir
          dies aufgrund eines Gesetzes oder Rechtsverfahrens müssen oder um
          unsere Produkte und Dienstleistungen zu liefern und zu verwalten.
          Außerdem können wir verpflichtet sein, Auskunft gegenüber einer
          Strafverfolgungsbehörde oder einer anderen Behörde zu erteilen. Ist
          eine Weiterleitung von Informationen für die Zusammenarbeit und
          somit Bereitstellung von Services von OmniMundi Ihnen gegenüber
          erforderlich oder erklären Sie Ihre Einwilligung, sind wir ebenfalls
          befugt, Daten preiszugeben. Auch, wenn Betriebsprüfungen anstehen,
          ist eine Offenlegung meist nicht zu vermeiden.</p>
        <p><b>7.2.
          Web Hosting Host </b>
        </p>
        <p>Unsere
          Schnittstellen und Datenbanken sind bei der Hetzner Online GmbH
          („<b>Dienstleister</b>“), Industriestr. 25, 91710 Gunzenhausen,
          gehostet. Unser Dienstleister darf auf die Daten nur im Rahmen
          unserer Weisungen zugreifen (Auftragsverarbeitung). Unser
          Dienstleister ergreift ebenfalls strenge technische Maßnahmen, um
          Ihre personenbezogenen Daten zu schützen. Unser Dienstleister gibt
          Ihre personenbezogenen Daten nicht an Dritte weiter, außer wenn die
          Weitergabe zur Abwicklung der vereinbarten Services erforderlich ist
          oder unser Dienstleister dies zur Einhaltung des Gesetzes oder einer
          gültigen und verpflichtenden Anweisung einer Regierungs- oder
          Regulierungsbehörde tun muss. Die dazu übermittelten Daten werden
          auf das notwendige Minimum beschränkt.<br/>
          Rechtsgrundlage für die
          Verarbeitung Ihrer Daten ist <i>Artikel 6 Absatz 1 Satz 1 Buchstabe f
            DSGVO</i>. Der Zweck der Datenverarbeitung besteht darin, dass unser
          Dienstleister uns die Möglichkeit bietet, Server zu nutzen. Wir
          löschen Ihre Daten, soweit sie für diesen Zweck nicht mehr
          erforderlich sind und keine gesetzlichen oder vertraglichen
          Aufbewahrungspflichten bestehen. Weitere Informationen zum
          Datenschutz sowie zur Speicherdauer Ihrer Daten etc. unseres
          Dienstleisters finden Sie unter: https://www.strato.de/datenschutz/
        </p>
        <p><b>7.3.
          Anwendungsentwicklung und Websitepflege</b></p>
        <p>Zur
          Anwendungsentwicklung und Websitepflege arbeiten wir mit einem
          externen Dienstleister („Dienstleister“) zusammen. <br/>
          Unser
          Dienstleister darf auf die Daten nur im Rahmen unserer Weisungen
          zugreifen (Auftragsverarbeitung). Unser Dienstleister ergreift
          ebenfalls strenge technische Maßnahmen, um Ihre personenbezogenen
          Daten zu schützen. Unser Dienstleister gibt Ihre personenbezogenen
          Daten nicht an Dritte weiter, außer wenn die Weitergabe zur
          Abwicklung des vereinbarten Service erforderlich ist oder er dies zur
          Einhaltung des Gesetzes oder einer gültigen und verpflichtenden
          Anweisung einer Regierungs- oder Regulierungsbehörde tun muss. Die
          dazu übermittelten Daten werden auf das notwendige Minimum
          beschränkt.<br/>
          Unser Dienstleister ist unseren Weisungen
          unterworfen und unterliegt strengen vertraglichen Beschränkungen in
          Bezug auf die Verarbeitung von personenbezogenen Daten. Hiernach ist
          eine Verarbeitung nur erlaubt, soweit es für die Durchführung der
          Dienstleistungen in unserem Namen oder zur Einhaltung rechtlicher
          Anforderungen nötig ist. Es wird im Vorhinein genau von uns
          festgelegt, welche Rechte und Pflichten unser Dienstleister in Bezug
          auf personenbezogene Daten haben soll.<br/>
          Rechtsgrundlage für die
          Verarbeitung Ihrer Daten ist <i>Artikel 6 Absatz 1 Buchstabe f DSGVO</i>.
          Der Zweck der Datenverarbeitung besteht darin, dass unser
          Dienstleister unsere Datenbank für uns pflegt und die Website
          entwickelt. Unser Dienstleister speichert Ihre Daten so lange, wie es
          für die Erreichung dieses Zwecks erforderlich ist.</p>
        <p><b>8.
          Sicherheitsstandards</b></p>
        <p>OmniMundi hat
          angemessene physische, technische und administrative
          Sicherheitsstandards implementiert, um personenbezogene Daten vor
          Verlust, Missbrauch, Änderung oder Zerstörung zu schützen. Unsere
          Dienstleister sind verpflichtet, die Vertraulichkeit
          personenbezogener Daten zu wahren. Zudem dürfen sie die Daten nur so
          nutzen, wie es dieser Datenschutzerklärung entspricht.<br/>
          Da uns
          die Sicherheit Ihrer Daten wichtig ist, wird ihr gesamter Besuch auf
          entfalter.de über eine sichere TLS-Verbindung abgewickelt. Werden
          persönliche Daten erhoben, findet die Datenübertragung ebenfalls
          TLS-verschlüsselt statt. Durch das TLS-Verschlüsselungsverfahren
          werden ihre Daten auf dem Weg durch das Internet vor unberechtigtem
          Zugriff geschützt. <br/>
          &nbsp;</p>
        <p><b>9.
          Änderungen an dieser Erklärung</b></p>
        <p>Wir können diese
          Datenschutzerklärung von Zeit zu Zeit aktualisieren. Wir empfehlen
          deshalb, diese Datenschutzerklärung regelmäßig durchzulesen, damit
          Sie unsere Datenschutzpraktiken kennen.
        </p>


        <p>Stand der Erklärung
          8. Januar 2021</p>
        <p><br/></p>
        <p><b>Haftungsausschluss</b>
        </p>
        <p>Die OmniMundi
          GmbH &amp; Co. KG übernimmt keine Garantie dafür, dass die auf
          dieser Website bereitgestellten Informationen vollständig, richtig
          und in jedem Falle aktuell sind. Dies gilt auch für alle
          Verbindungen (&quot;Links&quot;), die von dieser Website direkt oder
          indirekt verweisen. Die OmniMundi GmbH &amp; Co. KG ist für
          den Inhalt einer Seite, die mit einem Link erreicht wird, nicht
          verantwortlich. Die OmniMundi GmbH &amp; Co. KG behält sich
          das Recht vor, ohne vorherige Ankündigung, Änderungen oder
          Ergänzungen der bereitgestellten Informationen vorzunehmen. <br/>
          <br/>
          Die
          OmniMundi GmbH &amp; Co. KG begründet durch die Bereitstellung
          dieser Informationen kein Vertragsangebot über Auskünfte, Beratung
          oder ähnliche Vertragsbeziehungen. Jegliche Haftung für die Nutzung
          der Inhalte der Website oder die Richtigkeit der Inhalte oder die
          Erreichbarkeit der Website wird ausgeschlossen. <br/>
          <br/>
          Die
          OmniMundi GmbH &amp; Co. KG haftet daher nicht für konkrete,
          mittelbare und unmittelbare Schäden oder Schäden, die durch
          fehlende Nutzungsmöglichkeiten, Irrtümer, Datenverluste, oder
          entgangene Gewinne entstehen können, die im Zusammenhang mit der
          Nutzung von Dokumenten oder Informationen entstehen, die auf dieser
          Website zugänglich sind. Schadenersatzansprüche jeglicher Art gegen
          uns oder unsere Erfüllungsgehilfen, insbesondere wegen Verletzung
          vertraglicher Nebenpflichten, aus Verschulden bei Vertragsabschluss,
          falscher Beratung oder wegen Verletzung der Nachlieferungspflicht
          sind ausgeschlossen. <br/>
          <br/>
          In allen Fällen, in denen die
          Haftung in allgemeinen Geschäftsbedingungen nicht ausgeschlossen,
          aber der Höhe nach beschränkt werden kann, ist die Haftung stets
          beschränkt auf unseren Verkaufspreis des Teils der Bestellung und
          entsprechenden Rechnung aus dem die Schadensersatzansprüche
          resultieren.</p>


      </div>
    </div>

  </div>


</template>

<script>

	export default {
		name: 'Datenschutz',

		data() {
			// TODO: if you change the anaytics ID, change it here too!
			return {
				gaActive: (localStorage.getItem('www_entfalter_tracking_optout') != 0)
				//!window['ga-disable-UA-147534625-1']
			}
		},

		mounted() {
			// If a user did not click OK on the GAnalytics popup but clicked the link to here,
			// set the OK flag - so the notification won't come up again
			if (!localStorage.getItem('www_entfalter_tracking_optout')) {
				localStorage.setItem('www_entfalter_tracking_optout', 0)
			}    // default: OPT OUT
		},

		watch: {
			gaActive(v) {
				if (v)
					this.enableTracking()
				else
					this.disableTracking()
			}
		},

		methods: {
			disableTracking() {
				this.$ga.disable()
				localStorage.setItem('www_entfalter_tracking_optout', 1)
				// from now on analytics is disabled
			},
			enableTracking() {
				this.$ga.enable()
				localStorage.setItem('www_entfalter_tracking_optout', 0)
				// from now on analytics is enabled
			}
		}

	}
</script>

<style lang="scss">

</style>

